<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <v-row class="fill-height py-2">
    <v-col v-for="(kiosk, i) in allRegisteredKiosks" :key="i" md="2" sm="2" lg="3" xl="2" cols="12">
      <v-card class="cardBorderOffline" :class="getCardClass(kiosk.data().onlineStatus)" flat>
        <v-card-actions class="subtitle-1 mb-n10">
          <v-row no-gutters>
            <v-col cols="10">
              {{ kiosk.data().name }}
            </v-col>
            <v-col cols="1" v-if="isSuperAdmin || isAdmin">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" @click="lockKiosk(kiosk)">mdi-lock</v-icon>
                </template>
                <span>Screen Saver Mode</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1" v-if="isSuperAdmin || isAdmin">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" @click="remoteReferesh(kiosk)">mdi-refresh</v-icon>
                </template>
                <span>Remote Refresh Kiosk</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
        <br />
        <v-chip small class="ma-2">
          {{ kiosk.data().applicationId | trimLength }}
        </v-chip>
        <v-card-actions class="caption grey--text text--darken-1 py-0">
          {{ kiosk.data().location | trimLength }}
        </v-card-actions>
        <v-card-actions>
          <span class="text-caption blue--text text-decoration-underline" @click="selectKiosk(kiosk)"
            v-if="isSuperAdmin && kiosk.data().applicationType == 'wf'">
            Configurat... </span>
          <span v-if="isAdmin" class="text-caption blue--text text-decoration-underline" @click="avatarConfig(kiosk)">
            Avatar Conf... </span>
          <v-spacer></v-spacer>
          <div>
            <editAndDeleteKiosk kioskType="registered" :kioskIndex="kiosk"></editAndDeleteKiosk>
          </div>
          <kioskInformation :kioskObject="kiosk"></kioskInformation>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import editAndDeleteKiosk from "@/components/dialogBox/kiosks/editAndDeleteKiosk.vue";
import kioskInformation from "@/components/dialogBox/kiosks/kioskInformation.vue";
import kioskConfig from "@/components/dialogBox/kiosks/kioskConfig.vue";
import AvatarConfig from '../dialogBox/avatar/avatarConfig.vue';


export default {
  name: "registeredKioskList",
  components: {
    editAndDeleteKiosk,
    kioskConfig,
    kioskInformation,
    AvatarConfig
  },
  data() {
    return {
      loading: true,
      registeredKiosksHeaders: [
        { text: "Name", align: "start", value: "name" },
        { text: "Location", value: "location" },
        { text: "Email", value: "email" },
        { text: "Status", value: "onlineStatus" },
        { text: "Last Online", value: "last_changed" },
        { text: "Joined", value: "createdOn" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["allRegisteredKiosks", "userProfile", "isSuperAdmin", "isAdmin"])
  },
  methods: {
    getColor(status) {
      if (status === "Unavailable") return "error";
      if (status === "Busy") return "amber";
      if (status === "Available") return "success";
    },
    getCardClass(status) {
      if (status === "Unavailable") return "cardBorderOffline";
      if (status === "Busy") return "cardBorderOnline";
      if (status === "Available") return "cardBorderBusy";
    },
    remoteReferesh(kiosk) {
      this.$store.commit('setKioskActionConfirmationDialog', {
        kioskId: kiosk.id,
        action: 'referesh',
        show: true,
        header: 'Are you sure you want to referesh the kiosk?'
      })
    },
    lockKiosk(kiosk) {
      this.$store.commit('setKioskActionConfirmationDialog', {
        kioskId: kiosk.id,
        action: 'lock',
        show: true,
        header: 'Are you sure you want to lock the kiosk application?'
      })
    },
    selectKiosk(kioskInfo) {
      this.$store.commit('setSelectedKiosk', kioskInfo)
      this.$router.push('/configureKiosk')
    },
    avatarConfig(kiosk) {
      let temp = kiosk.data()
      temp['id'] = kiosk.id
      this.$store.commit('openAvatarConfigViewer', {
        show: true,
        kioskObject: temp
      })
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val === null) return val;
      if (val.length <= 30) {
        return val;
      }
      return `${val.substring(0, 25)}...`;
    },
  },
};
</script>

<style>
.cardBorderOffline {
  border-left: 6px solid #ff5252 !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}

.cardBorderOnline {
  border-left: 6px solid #4caf50 !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}

.cardBorderBusy {
  border-left: 6px solid #4caf50 !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}
</style>