import * as fb from "../../firebase";
import router from "../../router";
import axios from "axios";
import moment from "moment";
export default {
  state: {
    allRegisteredKiosks: [],
    allInvitedKiosks: [],
    invitedKioskProfile: {},
    kioskAnalytics: {
      qnaAnalytics: [],
      userSessionAnalytics: [],
      totalUserSessions: 0,
      totalQuestionsAsked: 0,
      overallPerformance: 0,
    },
    selectedKiosk: {},
    // Add New Kiosk Dialog
    addKioskDialog: false,
    kioskActionConfirmationDialog: {},
  },
  getters: {
    allRegisteredKiosks: (state) => state.allRegisteredKiosks,
    allInvitedKiosks: (state) => state.allInvitedKiosks,
    invitedKioskProfile: (state) => state.invitedKioskProfile,
    kioskAnalytics: (state) => state.kioskAnalytics,
    getKioskDialog: (state) => state.addKioskDialog,
    selectedKiosk: (state) => state.selectedKiosk,
    kioskActionConfirmationDialog: (state) =>
      state.kioskActionConfirmationDialog,
  },
  mutations: {
    setAllRegisteredKiosks(state, kiosks) {
      state.allRegisteredKiosks = kiosks;
    },
    setAllInvitedKiosks(state, kiosks) {
      state.allInvitedKiosks = kiosks;
    },
    setInvitedKioskProfile(state, kiosk) {
      state.invitedKioskProfile = kiosk;
    },
    setKioskAnalytics(state, analyticsObj) {
      state.kioskAnalytics = analyticsObj;
    },
    setKioskDialog(state, status) {
      state.addKioskDialog = status;
    },
    setSelectedKiosk(state, kioskObj) {
      state.selectedKiosk = kioskObj;
    },
    setKioskActionConfirmationDialog(state, obj) {
      state.kioskActionConfirmationDialog = obj;
    },
  },
  actions: {
    inviteNewKiosk({ state }, kiosk) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection
          .add({
            name: kiosk.name,
            email: kiosk.email,
            description: kiosk.description,
            location: kiosk.location,
            env: kiosk.kioskEnv,
            applicationId: kiosk.applicationId,
            kioskApplicationType: kiosk.kioskApplicationType,
            fdn: kiosk.fdn,
            createdOn: new Date(),
          })
          .then(() => {
            resolve("Kiosk Invitation sent successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    addNewFFKiosk({ dispatch }, kiosk) {
      return new Promise((resolve, reject) => {
        fb.auth
          .createUserWithEmailAndPassword(kiosk.email, kiosk.password)
          .then((cred) => {
            fb.kioskCollection.doc(cred.user.uid).set({
              name: kiosk.name,
              email: kiosk.email,
              location: kiosk.location,
              applicationId: kiosk.applicationId,
              env: kiosk.env,
              applicationType: kiosk.kioskApplicationType,
              configured: true,
              description: kiosk.description,
              onlineStatus: "Unavailable",
              createdOn: new Date(),
            });
          })
          .then(() => {
            fb.invitedKiosksCollection
              .doc(kiosk.invitationId)
              .delete()
              .then(() => {
                //fb.auth.signOut();
                //router.currentRoute.path === '/login'
                resolve("Kiosk account activated successfully.");
              });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    addNewWFKiosk({ dispatch }, kiosk) {
      return new Promise((resolve, reject) => {
        fb.auth
          .createUserWithEmailAndPassword(kiosk.email, kiosk.password)
          .then((cred) => {
            fb.kioskCollection
              .doc(cred.user.uid)
              .set({
                name: kiosk.name,
                email: kiosk.email,
                location: kiosk.location,
                description: kiosk.description,
                onlineStatus: "Unavailable",
                languages: [],
                modules: [],
                configured: false,
                englishQna: {
                  enable: true,
                  kbName: "",
                },
                spanishQna: {
                  enable: false,
                  kbName: "",
                },
                telepresence: false,
                physicalKeyboard: false,
                createdOn: new Date(),
              })
              .then(() => {
                fb.invitedKiosksCollection
                  .doc(kiosk.invitationId)
                  .delete()
                  .then(() => {
                    //fb.auth.signOut();
                    //router.currentRoute.path === '/login'
                    resolve("Kiosk account activated successfully.");
                  });
              });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    getInvitedKioskInformation({ commit }, kId) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection
          .doc(kId)
          .get()
          .then((kioskData) => {
            commit("setInvitedKioskProfile", kioskData.data());
            resolve("Fetched Kiosk Information");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    getAllRegisteredKiosks({ commit, getters }) {
      return new Promise((resolve, reject) => {
        let kioskList;
        if (getters.isSuperAdmin) {
          kioskList = fb.kioskCollection;
        } else if (getters.isClientAdmin) {
          kioskList = fb.kioskCollection.where("env", "in", ["Prod", "Stage"]);
        } else {
          kioskList = fb.kioskCollection.where("env", "in", ["Prod", "Stage"]);
        }
        kioskList.onSnapshot(
          (snapshot) => {
            if (snapshot.empty) console.log("Empty kiosk collection");
            let kioskList = snapshot.docs.sort((a, b) =>
              a.data().applicationId.localeCompare(b.data().applicationId)
            );
            // let onlineList = kioskList.filter(
            //   (kiosk) => kiosk.data().onlineStatus === "Available"
            // );
            // let offlineList = kioskList.filter(
            //   (kiosk) => kiosk.data().onlineStatus === "Unavailable"
            // );
            // commit("setAllRegisteredKiosks", onlineList.concat(offlineList));
            commit("setAllRegisteredKiosks", kioskList);
            resolve(snapshot.docs);
          },
          (error) => {
            reject(error.message);
          }
        );
      });
    },
    getAllInvitedKiosks({ commit }) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection.onSnapshot(
          (snapshot) => {
            if (snapshot.empty) console.log("Empty Kiosk collection");
            commit("setAllInvitedKiosks", snapshot.docs);
            resolve("Fetched all Invited Kiosks in collection");
          },
          (error) => {
            reject(error.message);
          }
        );
      });
    },
    updateRegisteredKioskInformation({ state }, kioskData) {
      return new Promise((resolve, reject) => {
        fb.kioskCollection
          .doc(kioskData.id)
          .update({
            name: kioskData.name,
            location: kioskData.location,
            description: kioskData.description,
            email: kioskData.email,
          })
          .then(() => {
            resolve("Kiosk Information updated successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    updateInvitedKioskInformation({ state }, kioskData) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection
          .doc(kioskData.id)
          .update({
            name: kioskData.name,
            location: kioskData.location,
            description: kioskData.description,
            email: kioskData.email,
          })
          .then(() => {
            resolve("Kiosk Information updated successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    deleteRegisteredKiosk({ state }, kioskId) {
      return new Promise((resolve, reject) => {
        fb.kioskCollection
          .doc(kioskId)
          .delete()
          .then(() => {
            resolve("Kiosk deleted successfully");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    deleteInvitedKiosk({ state }, kioskId) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection
          .doc(kioskId)
          .delete()
          .then(() => {
            resolve("Kiosk deleted successfully");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    saveKioskConfig({ state, getters }, kioskConfig) {
      return new Promise((resolve, reject) => {
        fb.kioskCollection
          .doc(kioskConfig.kId)
          .update({
            cmsLink: kioskConfig.cmsLink,
            videoLink: kioskConfig.videoLink,
            englishQna: kioskConfig.englishQna,
            spanishQna: kioskConfig.spanishQna,
            languages: kioskConfig.languages,
            modules: kioskConfig.modules,
            locationCode: kioskConfig.locationCode,
            defaultLocation: kioskConfig.defaultLocation,
            telepresence: kioskConfig.telepresence,
            avatarInfo: getters.selectedAvatar,
            configured: true,
            physicalKeyboard: kioskConfig.physicalKeyboard,
            hearingFile: kioskConfig.hearingFile,
          })
          .then(() => {
            resolve("Kiosk Configuration Saved Successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    autoRefreshKiosk({ getters }, id) {
      fb.kioskCollection.doc(id).update({
        autoRefresh: true,
      });
    },
    lockKioskApplication({ getters }, id) {
      fb.kioskCollection.doc(id).update({
        enableScreenSaver: true,
      });
    },
  },
};
