import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"px-3 mt-1 overflow-y-auto",attrs:{"flat":"","height":_vm.height}},[(_vm.allRegisteredKiosks.length !== 0)?_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.customKioskList),function(kiosk,i){return _c(VCol,{key:i,attrs:{"cols":_vm.kioskListDimensions.cols}},[_c(VCard,{staticClass:"pa-1 my-3",class:_vm.getCardClass(kiosk.data().onlineStatus),attrs:{"flat":"","width":"95%"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"10"}},[_c('span',{staticClass:"font-weight-bold ml-2"},[_vm._v(_vm._s(kiosk.data().name))]),_c('br'),_c(VChip,{staticClass:"ma-2",attrs:{"x-small":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(kiosk.data().applicationId))])])],1),_c(VCol,{attrs:{"cols":"2","align-self":"end","align":"end"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"medium":"","color":"primary"},on:{"click":function($event){return _vm.viewAnalytics(kiosk)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-google-analytics ")])]}}],null,true)},[_c('span',[_vm._v("View Analytics")])])],1)],1)],1)],1)],1)],1)}),1):_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCard,{attrs:{"width":"90%","flat":""}},[_c(VCardText,{staticClass:"font-weight-bold"},[_vm._v(" No Registered kiosks ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }