<template>
  <v-container fluid>
    <v-card flat :height="headerCardHeight">
      <v-row no-gutters class="fill-height">
        <v-col cols="3" align-self="center">
          <v-card-title class="text-h4">
            <v-icon large left color="primary lighten-3">
              mdi-google-analytics
            </v-icon>
            Kiosk Analytics
            <v-menu bottom max-height="400">
              <template v-slot:activator="{ on }">
                <v-chip class="text-h6 mt-3" outlined :color="getColor()" v-on="on" elevation="2">
                  <v-icon left class="mr-1">mdi-circle</v-icon>
                  {{ getKioskName }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-chip>

              </template>
              <v-list>
                <v-list-item v-for="(item, i) in allRegisteredKiosks" :key="i">
                  <v-list-item-title @click="getAnalytics(item)">{{ item.data().name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
        </v-col>
        <v-col cols="9" align-self="center">
          <v-row no-gutters>
            <v-col cols="4">
              <v-card width="85%" @click="tab = 1">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total User Sessions
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalUserSessions > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalUserSessions }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card width="85%" @click="tab = 2">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total Payments Transactions
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalPaymentSessions > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalPaymentSessions }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col cols="4" align-self="bottom">
              <v-card width="95%" class="">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Overall Performance
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span class="text-h5 font-weight-bold grey--text text--darken-2">
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="px-3 mt-3" flat>
      <v-tabs v-model="tab">
        <v-tab>
          <v-icon left>
            mdi-google-analytics
          </v-icon>
          Graphs</v-tab>
        <v-tab>
          <v-icon left>
            mdi-timer-check
          </v-icon>
          User Sessions</v-tab>
        <v-tab>
          <v-icon left>
            mdi-credit-card-outline
          </v-icon>
          Payments</v-tab>
        <v-spacer></v-spacer>
        <div class="font-weight-bold mt-4 mr-6 ">Date: {{ displayDate }}</div>
        <v-card flat width="200">
          <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2" ref="durationSelector">
            <template v-slot:item="data">
              <v-list-item v-if="data.item === 'Select Date'"
                @click="$store.commit('setSingleDatePicker', true); selectedDuration = 'Select Date'">
                Select Date
              </v-list-item>
              <v-list-item v-else-if="data.item === 'Select Week'"
                @click="$store.commit('setWeekDatePicker', true); selectedDuration = 'Select Week'">
                Select Week
              </v-list-item>
              <v-list-item v-else @click="durationSetter(data.item)">{{ data.item }}</v-list-item>
            </template>
          </v-select>
        </v-card>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-1">
        <v-tab-item>
          <reports :userSessionList="userSessionList" :selectedDuration="selectedDuration"></reports>
        </v-tab-item>
        <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item>
        <v-tab-item>
          <PaymentAnalytics :loading="loading" :paymentsList="paymentsList"></PaymentAnalytics>
        </v-tab-item>

        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>
      <v-card-title class="pa-0 text-caption d-flex align-end justify-end">
        <span>Last Synced: {{ getCurrentDate }} | <a @click="syncData()">Sync Data</a></span>
      </v-card-title>

    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import Reports from '../../components/dashboard/admin/kioskAnalytics/reports.vue';
import PaymentAnalytics from "../../components/dashboard/admin/kioskAnalytics/paymentAnalytics.vue";

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    Reports,
    PaymentAnalytics
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5.5,
      duration: ['Today', 'Yesterday', 'This Week', '2025', 'Select Date', 'Select Week', 'Fiscal Year', 'Select Year'],
      selectedDuration: 'Today',
      onlineStatus: '',
      date: new Date()
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "kioskAnalytics",
      "getWindowDimensions",
      "selectKioskAnalytics",
      "displayDate",
      "getPaymentToSession"
    ]),
    getCurrentDate() {
      return moment(this.date).format('lll')
    },
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getKioskName() {
      let temp;
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics)
      return temp[0].data().name
    },
    paymentsList() {
      return this.kioskAnalytics.paymentAnalytics
    },
    userSessionList() {
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        let startTime = moment(event.startDate);
        let endTime = moment(event.endDate);
        sessions.push({
          id: event.applicationSessionId,
          kioskId: event.applicationId,
          userSessionDate: event.startDate,
          userSessionDuration: endTime.diff(startTime),
          userSessionStartTime: event.startDate,
          userSessionEndTime: event.endDate,
          userSessionLogs: event.sessionData,
        });
      });
      return sessions.filter(session => session.kioskId === this.selectKioskAnalytics);
      //return sessions
    },
  },
  watch: {
    getPaymentToSession(newVal) {
      this.tab = 1
    },
    selectedKiosk(newVal) {
      this.loadData(newVal);
    },
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5.5;
    }
  },
  methods: {
    syncData() {
      console.log('Current Selection: ', this.selectedDuration)
      this.date = new Date()
      this.$store.dispatch('getKioskAnalytics', {
        type: this.selectedDuration,
        start: null,
        end: null
      })
    },
    getAnalytics(item) {
      this.$store.commit("setSelectKioskAnalytics", item.data().applicationId)
      this.$store.dispatch('getKioskAnalytics', {
        type: this.selectedDuration,
        start: null,
        end: null
      })
      //this.tab = 0
    },
    durationSetter(timeframe) {
      this.selectedDuration = timeframe
      this.$refs.durationSelector.isMenuActive = false;
      if (timeframe === 'Today') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else if (timeframe === 'Yesterday') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Yesterday',
          start: null,
          end: null
        })
      }
      else if (timeframe === 'This Week') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'This Week',
          start: null,
          end: null
        })
      } else if (timeframe === '2025') {
        this.$store.dispatch('getKioskAnalytics', {
          type: '2025',
          start: null,
          end: null
        })
      } else if (timeframe === 'Fiscal Year') {
        this.$store.commit("setFiscalYearPicker", true)
      } else if (timeframe === 'Select Year') {
        this.$store.commit("setYearPicker", true)
      } else if (timeframe === null) {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      }
    },
    getColor(status) {
      let temp;
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics)
      //return temp[0].data().name
      if (temp[0].data().onlineStatus === 'Available') {
        return 'success'
      } else {
        return 'error'
      }
      // if (status === "Unavailable") return "error";
      // if (status === "Busy") return "amber";
      // if (status === "Available") return "success";
    }
  },
  mounted() {
    if (this.allRegisteredKiosks.length === 0) {
      this.$store.dispatch("getAllRegisteredKiosks");
    }
  },
};
</script>
<style>
.cardBorderStandard {
  border-left: 0.5px solid #bdbdbd !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}
</style>